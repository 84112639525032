.mini-contact {
  display: flex;
  flex-direction: row;
  gap: 35px;
  align-items: center;
  overflow: hidden;
  height: 200px;
  width: 100%;
  max-width: 800px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
}

.mini-contact-image {
  height: 200px;
  width: auto;
}

.mini-contact-lottie {
  display: none;
}

.mini-contact-info {
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
}

.mini-contact-text {
  font-family: 'Inter';
  font-size: 18px;
  color: var(--color-black);
  font-weight: 500;
  max-width: 600px;
}

@media (max-width: 600px) {
  .mini-contact-image {
    height: 100px;
  }
}

@media (max-width: 500px) {
  .mini-contact {
    height: 140px;
  }
  .mini-contact-info {
    gap: 25px;
  }
}

@media (max-width: 460px) {
  .mini-contact {
    min-height: 140px;
    height: auto;
  }
  .mini-contact-image {
    height: 72px;
  }
}

@media (max-width: 420px) {
  .mini-contact {
    flex-wrap: wrap;
    justify-content: center;
    padding: 25px 15px;
    gap: 15px;
  }
  .mini-contact-image {
    display: none;
  }
  .mini-contact-lottie {
    display: flex;
  }
  .mini-contact-info {
    padding: 0;
  }
}
