.hero-section {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.hero-container {
  height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.hero-image {
  width: 100%;
  object-fit: cover;
  height: 400px;
  align-self: center;
  display: flex;
}

.hero-text-container {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0 25px;
  gap: 25px;
}

.hero-text-title {
  font-size: 36px;
  font-family: 'Inter';
  font-weight: 700;
  color: white;
  text-align: center;
  max-width: 750px;
  display: -webkit-box; /* Establish a flex container for the text */
  -webkit-box-orient: vertical; /* Orient the box vertically */
  -webkit-line-clamp: 2; /* Clamp the text to two lines */
  line-clamp: 2;
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Add ellipsis (...) to the overflowed text */
  line-height: 1.5em; /* Set the line height to ensure text is displayed properly */
  max-height: 3em; /* Maximum height for two lines (line-height * 2) */
  user-select: none;
  margin: 0;
}

.hero-text-description {
  font-size: 20px;
  font-family: 'Inter';
  font-weight: 300;
  color: white;
  text-align: center;
  max-width: 700px;
  display: -webkit-box; /* Establish a flex container for the text */
  -webkit-box-orient: vertical; /* Orient the box vertically */
  -webkit-line-clamp: 2; /* Clamp the text to two lines */
  line-clamp: 2;
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Add ellipsis (...) to the overflowed text */
  line-height: 1.5em; /* Set the line height to ensure text is displayed properly */
  max-height: 3em; /* Maximum height for two lines (line-height * 2) */
  user-select: none;
}

.hero-wave {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
}

@media (max-width: 635px) {
  .hero-text-title {
    font-size: 32px;
  }
}

@media (max-width: 560px) {
  .hero-text-title {
    font-size: 21px;
  }
  .hero-text-description {
    font-size: 18px;
  }
}
