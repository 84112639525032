.message-box {
  background-color: var(--color-bone);
  border-radius: 5px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
  display: flex;
  flex-direction: column;
  padding: 25px;
  max-width: 1200px;
}

.message-box-main {
  display: flex;
  flex-direction: row;
  gap: 35px;
  flex-wrap: wrap;
  justify-content: center;
}

.message-box-left {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
}

.input-row {
  display: flex;
  flex-direction: row;
  gap: 35px;
  flex-wrap: wrap;
  width: 100%;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
  min-width: 200px;
  position: relative;
}

.input-label {
  font-size: 14px;
  color: var(--color-grey);
  font-family: 'Inter';
  font-weight: 400;
}

.form-input {
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 18px;
  color: var(--color-black);
  display: flex;
  font-family: 'Inter';
}

.form-area {
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 18px;
  color: var(--color-black);
  display: flex;
  height: 120px;
  resize: none;
  font-family: 'Inter';
}

.error-text {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  color: var(--color-red);
  position: absolute;
  bottom: -20px;
  text-wrap: nowrap;
}

.result-text {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  color: var(--color-black);
  text-align: center;
}

.message-box-right {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  min-width: 280px;
  flex: 1;
}

.right-item {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  color: var(--color-black);
  text-wrap: nowrap;
  text-align: center;
  display: block;
  font-style: italic;
}

.right-item:hover {
  color: var(--color-accent);
}

.right-item-image {
  height: 64px;
  width: 64px;
}

.message-sent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
