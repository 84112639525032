.footer {
  width: 100%;
  padding: 35px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;
  background-color: var(--color-black);
}

.footer-social-media {
  display: flex;
  flex-direction: row;
  gap: 35px;
}

.footer-contact-us {
  display: flex;
  flex-direction: row;
  gap: 35px;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-contact-us a {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.footer-media-text {
  font-size: 12px;
  color: var(--color-white);
  font-weight: 500;
  font-family: 'Inter';
  text-transform: uppercase;
}

.footer-media-icon {
  height: 24px;
  width: 24px;
}

.footer-navigation {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.footer-navigation-item {
  color: var(--color-white);
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter';
  text-transform: uppercase;
  user-select: none;
}

.footer-navigation-item:hover {
  color: var(--color-pearl);
}

.active-footer-item {
  color: var(--color-pearl);
}

@media (max-width: 560px) {
  .footer-navigation {
    flex-wrap: wrap;
    gap: 0;
    width: 100%;
  }
  .footer-navigation-item {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--color-grey);
    padding: 25px 0;
  }
}
