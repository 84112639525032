.featured-service {
  border-radius: 5px;
  background-color: var(--color-white);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
  max-width: 350px;
  width: 100%;
  height: 275px;
  overflow: hidden;
  padding-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.featured-service:hover .featured-service-info {
  bottom: 0; /* Slide up into view */
}

.featured-service-image {
  height: 79%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.featured-service-info {
  background-color: var(--color-white);
  border-radius: 5px;
  position: absolute;
  transition: bottom 0.5s ease; /* Smooth slide up transition */
  bottom: -58%;
  width: 100%;
  height: 240px;
  user-select: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Add shadow */
}

.featured-service-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-accent);
  font-family: 'Inter';
  text-align: center;
  padding: 15px 10px 0 10px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.featured-service-description {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-grey);
  font-family: 'Inter';
  text-align: center;
  padding: 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical; /* Orient the box vertically */
  -webkit-line-clamp: 6; /* Clamp the text to two lines */
  line-clamp: 6;
  display: -webkit-box; /* Establish a flex container for the text */
  max-width: 350px;
  margin: 0 auto;
}

.info-divider {
  width: 100px;
  height: 1px;
  background-color: var(--color-grey);
  margin: 0 auto;
}

@media (max-width: 800px) {
  .featured-service {
    max-width: 100%;
    width: 100%;
  }
  .featured-services-home {
    flex-wrap: wrap;
  }
}
