.featured-services-home {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 25px;
  justify-content: center;
}

.about-home-wrapper {
  width: 100%;
  background-color: var(--color-white);
}

.about-home-container {
  padding: 35px 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 35px;
  justify-content: center;
}

.about-home-image {
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-home-text {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
}

.about-home-title-small {
  font-family: 'Inter';
  font-size: 20px;
  color: var(--color-grey);
  font-weight: 500;
}

.about-home-title-large {
  font-family: 'Inter';
  font-size: 32px;
  color: var(--color-sepia);
  font-weight: 600;
}

.about-home-paragraph {
  font-family: 'Inter';
  font-size: 18px;
  color: var(--color-black);
  font-weight: 400;
  max-width: 600px;
}

.featured-feedback-home {
  display: flex;
  flex-direction: row;
  gap: 25px;
  overflow-x: auto;
  padding: 0 25px 10px 25px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.featured-feedback-home::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

@media (max-width: 870px) {
  .about-home-image {
    max-width: 100%;
    width: 100%;
    height: 200px;
  }
  .about-home-container {
    flex-wrap: wrap;
  }
}
