.services-description {
  font-family: 'Inter';
  font-size: 18px;
  color: var(--color-black);
  font-weight: 400;
  max-width: 600px;
  text-align: center;
}

.featured-services {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 25px;
  justify-content: center;
  max-width: 1200px;
  flex-wrap: wrap;
}
