.styled-wrapper {
  width: 100%;
}

.styled-wrapper-container {
  max-width: 1400px;
  padding: 35px 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.styled-wrapper-title-small {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Inter';
  color: var(--color-grey);
  text-align: center;
  width: 100%;
}

.styled-wrapper-title-large {
  font-size: 32px;
  font-weight: 600;
  font-family: 'Inter';
  color: var(--color-black);
  text-align: center;
  width: 100%;
  margin: 0;
}

.styled-wrapper-titles {
  display: flex;
  gap: 25px;
  margin-bottom: 35px;
  flex-direction: column;
}
