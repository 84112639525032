.timeline {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  position: relative;
}

.line {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 3px;
  background-color: var(--color-sepia);
  border-radius: 5px;
}

.timeline-event {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.timeline-event-even {
  flex-direction: row-reverse;
}

.timeline-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-basis: 0;
  flex-grow: 1;
}

.timeline-info-odd {
  align-items: flex-end;
}

.timeline-title-section {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.timeline-title-number {
  font-size: 28px;
  color: var(--color-black);
  font-family: 'Inter';
  font-weight: 500;
  height: 50px;
  width: 50px;
  background-color: var(--color-black);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-pearl);
}

.timeline-title {
  font-size: 20px;
  color: var(--color-black);
  font-family: 'Inter';
  font-weight: 600;
  max-width: 400px;
}

.timeline-description {
  font-size: 16px;
  color: var(--color-grey);
  font-family: 'Inter';
  font-weight: 400;
  max-width: 400px;
  line-height: 140%;
}

.timeline-description-odd {
  text-align: end;
}

.timeline-dot {
  background-color: var(--color-sepia);
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 2px solid var(--color-white);
  margin: 0 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
}

.timeline-spacer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}

@media (max-width: 800px) {
  .timeline-dot {
    display: none;
  }
  .timeline-spacer {
    display: none;
  }
  .line {
    display: none;
  }
  .timeline-description {
    text-align: left;
    max-width: 100%;
  }
  .timeline-info {
    align-items: start;
  }
  .timeline-event {
    flex-direction: row;
  }
}
