.styled-button {
  background-color: var(--color-accent);
  border-radius: 5px;
  height: 48px;
  min-width: 220px;
  width: max-content;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
  transition: box-shadow 0.3s ease; /* Smooth transition for shadow */
  padding: 0 25px;
}

.styled-button:hover {
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.button-text {
  font-family: 'Inter';
  font-weight: inherit;
  color: inherit;
  font-size: 18px;
  user-select: none;
}
