.about-description {
  font-family: 'Inter';
  font-size: 18px;
  color: var(--color-black);
  font-weight: 400;
  max-width: 600px;
  text-align: center;
}

.about-more {
  display: flex;
  flex-direction: row;
  gap: 50px;
  flex-wrap: wrap;
  max-width: 1000px;
}

.about-philosophy {
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex-basis: 0;
  flex-grow: 1;
}

.about-philosophy-text {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.about-philosophy-description {
  font-family: 'Inter';
  font-size: 18px;
  color: var(--color-black);
  font-weight: 400;
}

.about-philosophy-image {
  height: 230px;
  width: 100%;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-qualities {
  display: flex;
  flex-direction: column;
  gap: 35px;
  flex-basis: 0;
  flex-grow: 1;
}

.about-qualities-divider {
  width: 80%;
  height: 1px;
  background-color: var(--color-grey);
  max-width: 300px;
}

.about-title {
  font-family: 'Inter';
  font-size: 32px;
  color: var(--color-black);
  font-weight: 600;
}

.unique-qualities {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.unique-quality {
  font-family: 'Inter';
  font-size: 18px;
  color: var(--color-black);
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
}

.unique-quality::before {
  content: '';
  background-color: var(--color-grey);
  border-radius: 50%;
  height: 5px;
  width: 5px;
  display: inline-block;
}

.about-values {
  display: flex;
  flex-direction: row;
  gap: 25px;
  flex-wrap: wrap;
  max-width: 1400px;
}

.value-item {
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 1 1 0;
  min-width: 280px;
}

.value-title {
  font-family: 'Inter';
  font-size: 18px;
  color: var(--color-accent);
  font-weight: 500;
}

.value-description {
  font-family: 'Inter';
  font-size: 18px;
  color: var(--color-black);
  font-weight: 400;
}

@media (max-width: 800px) {
  .about-philosophy {
    flex-basis: auto;
  }
}
