.achievement-bar {
  width: 100%;
  height: 126px;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
}

.achievement-bar-container {
  max-width: 1400px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 25px;
  gap: 15px;
  background-color: var(--color-white);
}

.achievement-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.achievement-statistic {
  color: var(--color-accent);
  font-family: 'Inter';
  font-weight: 600;
  font-size: 32px;
  text-align: center;
}

.achievement-name {
  color: var(--color-black);
  font-family: 'Inter';
  font-weight: 300;
  font-size: 16px;
  text-align: center;
}

@media (max-width: 560px) {
  .achievement-item {
    max-width: 100%;
    width: 100%;
  }
  .achievement-bar-container {
    flex-wrap: wrap;
  }
  .achievement-bar {
    height: auto;
    padding: 15px 0 25px 0;
  }
}
