* {
  box-sizing: border-box;
  text-decoration: none;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter.ttf') format('truetype');
  font-weight: 100 900; /* Define the range of weights available */
  font-style: normal; /* or 'italic', if applicable */
}

:root {
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  /* --color-accent: #556b2f; */
  --color-accent: #26890d;
  --color-sepia: #68350a;
  --color-umber: #955d2c;
  --color-pearl: #e2d7be;
  --color-khaki: #c0b3a1;
  --color-bone: #d6cebd;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-grey: #4a4a4a;
  --color-red: #ff0033;
}

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-black);
}
