.nav-bar {
  height: 100px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
}

.nav-container {
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  height: 100%;
  width: 100%;
  padding: 0 25px;
}

.nav-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.nav-company-name {
  font-size: 24px;
  letter-spacing: -2%;
  color: var(--color-black);
  font-family: 'Inter';
  font-weight: 600;
  line-height: 20px;
}

.nav-engineering {
  text-transform: uppercase;
  font-size: 14px;
}

.nav-logo {
  height: 72px;
  width: 72px;
}

.nav-list {
  display: flex;
  flex-direction: row;
  gap: 25px;
  list-style: none;
  padding: 0;
}

.nav-list-item {
  font-size: 20px;
  color: var(--color-black);
  font-family: 'Inter';
  font-weight: 300;
}

.nav-list-item-active {
  color: var(--color-accent) !important;
  font-family: 'Inter';
  font-weight: 500;
}

.nav-desktop-item {
  position: relative;
}

.nav-desktop-item:hover {
  color: var(--color-accent);
}

.nav-desktop-item.nav-list-item-active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: var(--color-grey);
  left: 50%;
  transform: translateX(-50%);
}

.nav-list-item a {
  text-decoration: none;
  color: inherit;
  user-select: none;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;
}

.mobile-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: none;
  justify-content: flex-end;
  cursor: pointer;
  transition:
    right 0.3s ease,
    background-color 0.1s ease;
}

@media (max-width: 700px) {
  .nav-list {
    display: none;
  }
  .hamburger-menu {
    display: flex;
  }
  .mobile-backdrop {
    display: flex;
  }
}

.mobile-nav-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  align-items: flex-end;
  background-color: var(--color-white);
  height: 100%;
  width: 75%;
  margin: 0;
  align-items: center;
  cursor: default;
}

.mobile-nav-list-item {
  font-size: 20px;
  color: var(--color-black);
  font-family: 'Inter';
  font-weight: 300;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--color-black);
}

.mobile-nav-list-item:first-child {
  border: none;
}

.mobile-nav-list-item a {
  text-decoration: none;
  color: inherit;
  padding: 25px 0;
  display: flex;
  width: 100%;
  user-select: none;
  justify-content: center;
}

.mobile-nav-logo {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bone);
  padding: 25px 0;
  text-align: left;
}
